
function article_1() {
	return {
		date: "June 2023",
		title: "EEG-Based Brain-Computer Interactions in Immersive Virtual and Augmented Reality: A Systematic Review",
		link: "https://doi.org/10.1145/3593226",
		description: "My first first author research paper, a review of BCI interactions in immersive technology.",
		keywords: [
			"brain-computer interaction",
			"virtual reality",
			"augmented reality",
			"immersive technology",
			"systematic review",
			"Emeka",
			"Chukwuemeka Nwagu",
			"Emeka Nwagu",
		],
	};
}

function article_2() {
	return {
		date: "June 2023",
		title: "Chai Wallpaper: A Mindfulness-Based Persuasive Intervention for Absent-Minded Smartphone Usage",
		link: "https://doi.org/10.1145/3563359.3597444",
		description: "A late-breaking work based on my Master's thesis research.",
		keywords: [
			"Problematic smartphone use",
			"persuasive technology",
			"mindfulness",
			"Emeka",
			"Chukwuemeka Nwagu",
			"Emeka Nwagu",
		],
	};
}

function article_3() {
	return {
		date: "March 2023",
		title: "VoxelAuth",
		link: "https://ieeexplore.ieee.org/abstract/document/10108649",
		description: "A 3D authentication system for VR.",
		keywords: [
			"Virtual reality",
			"3DUI",
			"authentication",
			"Emeka",
			"Chukwuemeka Nwagu",
			"Emeka Nwagu",
		],
	};
}

const myArticles = [article_1, article_2, article_3];

export default myArticles;

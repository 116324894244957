const INFO = {
	main: {
		title: "Emeka's Homepage",
		name: "Emeka Nwagu",
		email: "emeka@nwagu.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/c_14_u",
		github: "https://github.com/nwagu",
		linkedin: "https://linkedin.com/in/nwagu",
		// instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/users/8196427/nwagu",
		// facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Hi! I'm Emeka. Software engineer, future enthusiast",
		description:
			"I am a software engineer with expertise in native and hybrid mobile development. I have experience building enterprise mobile applications and libraries in the fintech industry. I have also published some HCI research in respectable venues. I think humanity's technology-driven future will be extraordinary, and I enjoy being part of teams who build the cool stuff.",
	},

	about: {
		title: " ",
		description: "",
	},

	articles: {
		title: " ",
		description: " ",
	},

	projects: [
		{
			title: "Chai wallpaper",
			description:
				"A persuasive intervention for absent-minded smartphone use",
			logo: "../chai.png",
			linkText: "View Project",
			link: "https://chai.nwagu.com/",
		},

		{
			title: "Chess Boy",
			description:
				"An open-source chess mobile app with bluetooth multiplayer mode. Built with Kotlin Multiplatform Mobile",
			logo: "../chessboy.png",
			linkText: "View Project",
			link: "https://github.com/nwagu/chess-boy",
		},

		{
			title: "Mmuta",
			description:
				"I contribute to the Mmuta app for learning the Igbo language.",
			logo: "../mmuta.png",
			linkText: "View Project",
			link: "https://play.google.com/store/apps/details?id=com.nkowaokwu.mmuta",
		},

		{
			title: "X",
			description: "My public diary or blog",
			logo: "../x.png",
			linkText: "View Project",
			link: "https://x.nwagu.com",
		},

		{
			title: "Moniepoint POS",
			description: "POS app for Moniepoint",
			logo: "../moniepoint.png",
			linkText: "View Project",
			link: "https://moniepoint.com/ng",
		},

		{
			title: "Monnify",
			description: "Monnify Mobile SDKs",
			logo: "../monnify.png",
			linkText: "View Project",
			link: "https://monnify.com/",
		},
	],
};

export default INFO;
